import { firestore } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

export const FireName = (name: string, value: number) => {
  return new Promise(async (resolve, reject) => {
    resolve(
      await addDoc(collection(firestore, 'navn'), {
        navn: name,
        verdi: value,
      })
    );
  });
};
