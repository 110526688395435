import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


type DialogProps = {
  ToogleDeleteDialog: boolean;
  handleToogleDeleteDialog: () => void;
  DeleteAsset: () => void;
  label: string;
};

export const DeleteDialoge = ({
  ToogleDeleteDialog,
  handleToogleDeleteDialog,
  label,
  DeleteAsset,
}: DialogProps) => {
  return (
    <Dialog
      open={ToogleDeleteDialog}
      onClose={handleToogleDeleteDialog}
      aria-labelledby='responsive-dialog-title'>
      <DialogTitle id='responsive-dialog-title'>{`Delete Asset ${label}`}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleToogleDeleteDialog}>
          Cancel
        </Button>
        <Button onClick={DeleteAsset} autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
