import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCqpR0Gwj2IPeim0p5SDID50EvGPEcNfn4',
  authDomain: 'investrelate-e2e04.firebaseapp.com',
  projectId: 'investrelate-e2e04',
  storageBucket: 'investrelate-e2e04.appspot.com',
  messagingSenderId: '209204087735',
  appId: '1:209204087735:web:cc70731fa861abbefff632',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
