import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import image1 from '../assets/1.jpg';
import image2 from '../assets/2.jpg';
import image3 from '../assets/3.jpg';
import image4 from '../assets/4.jpg';
import image5 from '../assets/5.jpg';
import image6 from '../assets/6.jpg';
import image7 from '../assets/7.jpg';
import image8 from '../assets/8.jpg';
import image9 from '../assets/9.jpg';

import { FireName } from '../fire/FireName';

type NameProps = {};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const NamePool = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [slidervalue, setslidervalue] = useState<number>(5);
  const [width, setwidth] = useState<number>(0);

  const [finished, setfinished] = useState(false);

  let data = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  const cardRef = useRef<any>(null);

  const id = data.id ? parseInt(data.id) : 0;

  const names = [
    {
      navn: 'ylva',
      short:
        'Navnet Ylva er først kjent fra Sverige på 1200-tallet. Betydningen er ulv, og kommer fra det ordet.',
    },
    {
      navn: 'mathea',
      short:
        'Mathea er jentevariant av Matteus og Mathias. Både Matteus og Mattias/Mathias er navn i Bibelen med hebraisk opphav og betydning gave fra Gud. Matteus var en av Jesu opprinnelige tolv apostler og forfatter av Matteus-evangeliet. Mattias var den nye disippelen som kom da apostelen Judas ble skiftet ut.',
    },
    {
      navn: 'tomine',
      short:
        'Tomine er jentevariant av Tom. Tom er nederlandsk, engelsk og amerikansk kjæleform og kortform av Tomas. Tomas er et navn fra Bibelen og det hebraiske språket brukt blant jødene. Betydningen er tvilling, som vil si to like som hører sammen.',
    },
    {
      navn: 'Hedvig',
      short:
        'Hedvig kommer fra tysk. Begge delene, hed og vig, betyr kamp og strid.',
    },
    {
      navn: 'Vera',
      short:
        'Vera har opphav i det slaviske ordet vera som betyr tro (gudstro). Det slaviske ordet er språklig beslektet med latinsk vera/verus, som betyr sann, som også blir brukt som forklaring på navnet. Navnet har først og fremst gammel tradisjon knyttet til den ortodokse kirken i Russland og øvrige Øst-Europa.',
    },
    {
      navn: 'miriam',
      short:
        'Navnet Miriam finnes i Det gamle testamentet og er en eldre variant av Maria. Miriam er et bibelsk navn og kan bety elsket, stor og sterk, sjøens stjerne , opprører eller dråpe fra havet - alt etter hvordan man tolker navnets opphav. Den eldste kjente varianten i Palestina var Maryam på språket arameisk, dernest kom Miriam på hebraisk. Disse ble for det meste til Maria i greske og latinske bibeloversettelser, og ellers på mange språk. Maryam er formen vi kjenner fra Koranen, og det navnet er et mye brukt navn blant muslimer.',
    },
    {
      navn: 'iben',
      short:
        'Jentenavnet Iben er først og fremst kjent fra Danmark og Norge, og har dansk opphav. I Danmark ble Iben og Ibben brukt som kortform på 1700- og 1800-tallet av dobelte kvinnenavn, der det første begynte på I- og det andre på B, blant annet Ida Bengta. I nyere tid har navnet i Danmark også regnet det mørke har mørke treslaget ibenholt som inspirasjonskilde, på samme måten som navnet Ebony i USA.',
    },
    {
      navn: 'hermine',
      short:
        'Hermine er kvinnelig variant av Herman. Herman er et tysk navn og betyr hær og mann.',
    },
    {
      navn: 'josefine',
      short:
        'Josefine er laget av guttenavnet Josef. Josef er kjent fra Bibelen, som mannen til Maria, moren til Jesus. Josef betyr Gud vil øke, altså at Gud vil gi ham mange barn og mange etterkommere.',
    },
  ];

  useEffect(() => {
    if (cardRef.current) {
      setwidth(cardRef?.current?.offsetWidth - 10);
    }
  }, [cardRef]);

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
  ];

  const current = names[id];
  const currentName = names[id].navn;

  const next = () => {
    setLoading(true);

    FireName(currentName, slidervalue).then(() => {
      setLoading(false);
      const url = id + 1 == names.length ? `/oversikt` : `/navn/${id + 1}/`;
      navigate(url);
    });
  };

  useEffect(() => {
    setslidervalue(5);
  }, [currentName]);

  const marks = [
    {
      value: 0,
      label: 0,
    },
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
      value: 5,
      label: 5,
    },
    {
      value: 6,
      label: 6,
    },
    {
      value: 7,
      label: 7,
    },
    {
      value: 8,
      label: 8,
    },
    {
      value: 9,
      label: 9,
    },
    {
      value: 10,
      label: 10,
    },
  ];

  return (
    <Grid container justifyContent={'center'} alignSelf='center'>
      <Grid item xs={12} md={6} lg={4}>
        <Card ref={cardRef}>
          <CardMedia
            component='img'
            image={images[Math.floor(Math.random() * images.length)]}
          />
          <CardContent>
            <Typography variant='h2' style={{ margin: 20 }}>
              {currentName[0].toUpperCase() + currentName.substring(1)}
            </Typography>
            <Typography variant='body1' color='text.secondary'>
              {currentName[0].toUpperCase() +
                currentName.substring(1) +
                ' Steinrud Nicolaysen'}
            </Typography>
            <Typography variant='body2' mt={5} color='text.secondary'>
              {current.short}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Box sx={{ width: width, marginTop: 4, marginBottom: 4 }}>
              <Slider
                aria-label='Velg'
                value={slidervalue}
                onChange={(e: any) => setslidervalue(e?.target?.value)}
                valueLabelDisplay='auto'
                step={1}
                marks={marks}
                min={0}
                max={10}
              />
            </Box>
          </CardActions>
          <CardActions style={{ justifyContent: 'end' }}>
            <Button onClick={next}>
              {loading ? <CircularProgress size='small' /> : 'Neste'}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
