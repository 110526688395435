import { useState, useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { collection, getDocs } from 'firebase/firestore';

import { firestore } from '../fire/firebase';
import _ from 'lodash';

export const Oversikt = () => {
  const [labels, setlabels] = useState<string[]>([]);
  const [data, setData] = useState<any>([]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );
  useEffect(() => {
    console.log('START FETCHG');
    const da: any = {};
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'navn'));
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const name = docData.navn;
        const verdi = docData.verdi;

        if (name in da) {
          da[name] = verdi + da[name];
        } else {
          da[name] = verdi;
        }
      });

      var result = {
        label: 'navn',
        data: Object.values(da),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      };
      setlabels(Object.keys(da));
      setData([result]);
    };

    fetchData();
  }, []);

  const options = {
    animation: {
      duration: 500,
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        color: 'rgba(240,240,240,1)',
        display: function (context: any) {
          return context.dataset.data[context.dataIndex] > 15;
        },
        font: {
          weight: 'bold' as const,
        },
        formatter: Math.round,
      },
    },
    aspectRatio: 5 / 3,
    layout: {
      padding: {
        top: 24,
        right: 16,
        bottom: 0,
        left: 8,
      },
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        hoverRadius: 7,
        radius: 5,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  console.log(data);

  return (
    <Grid container justifyContent={'center'} alignSelf='center'>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardContent>
            <Bar
              options={options}
              data={{
                labels,
                datasets: data,
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
