import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Overview } from './pages/Overview';
import { Name } from './pages/Name';
import { Ferdig } from './pages/Ferdig';
import { Oversikt } from './pages/Oversikt';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Overview />} />
          <Route path='ferdig' element={<Ferdig />} />
          <Route path='navn' element={<Name />}>
            <Route path=':id' element={<Name />} />
          </Route>
          <Route path='oversikt' element={<Oversikt />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
