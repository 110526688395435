import { firestore } from './firebase';
import { doc, getDoc } from 'firebase/firestore';

type AssetProps = {
  label: string;
  data: (number | null)[];
  backgroundColor: string;
  hidden: boolean;
};

type AssetDoc = {
  assets: AssetProps[];
  liabilities: AssetProps[];
};

export const FetchAssets = (ticker: string) => {
  return new Promise(async (resolve, reject) => {
    const assetsDoc = doc(firestore, 'assets', ticker.toLowerCase());
    const FireDoc = await getDoc(assetsDoc);
    const data = FireDoc.data() as AssetDoc;
    if (data) {
      resolve(data);
    } else {
      reject();
    }
  });
};
