import { useRef, useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import _ from 'lodash';

type Props = {
  labels: string[];
  datasets: any[];
  setSelected: (selected: any) => void;
  setDatasets: any;
};

export const BarChart = ({
  labels,
  datasets,
  setSelected,
  setDatasets,
}: Props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );
  const theme = useTheme();
  const SmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const chartRef = useRef<any>(null);

  const onClick = (event: any) => {
    const element = getElementAtEvent(chartRef.current, event);
    const datasetIndex = element?.[0]?.datasetIndex;
    const index = element?.[0]?.index;

    if (datasetIndex > 0 && index > 0) {
      setSelected({
        datasetIndex: datasetIndex,
        index: index,
      });
    }
  };

  const options = {
    animation: {
      duration: 500,
    },
    plugins: {
      legend: {
        display: true,
        position: SmallDevice ? ('top' as const) : ('right' as const),
        onClick: (evt: any, legendItem: any, legend: any) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          const d = _.cloneDeep(datasets);

          if (legendItem.hidden) {
            legendItem.hidden = false;
            d[index].hidden = false;
            ci.show(index);
          } else {
            legendItem.hidden = true;
            d[index].hidden = true;
            ci.hide(index);
          }

          setDatasets(d);
        },
      },

      datalabels: {
        color: 'rgba(240,240,240,1)',
        display: function (context: any) {
          return context.dataset.data[context.dataIndex] > 15;
        },
        font: {
          weight: 'bold' as const,
        },
        formatter: Math.round,
      },
    },
    aspectRatio: 5 / 3,
    layout: {
      padding: {
        top: 24,
        right: 16,
        bottom: 0,
        left: 8,
      },
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        hoverRadius: 7,
        radius: 5,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <Bar
      datasetIdKey='label'
      options={options}
      data={{
        labels,
        datasets: datasets,
      }}
      ref={chartRef}
      onClick={onClick}
    />
  );
};
