import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import { CirclePicker } from 'react-color';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import Stack from '@mui/material/Stack';

type AssetProps = {
  label: string;
  data: (number | null)[];
  backgroundColor: string;
};

type Props = {
  ToogleAddDialog: boolean;
  handleToogleAddDialog: () => void;
  SaveNewAsset: (newAsset: any) => void;
};

const palette = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

export const AddDialog = ({
  ToogleAddDialog,
  handleToogleAddDialog,
  SaveNewAsset,
}: Props) => {
  const [Liability, setLiability] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('');
  const [InputValue, setValue] = useState<number>(0);
  const [backgroundColor, setbackgroundColor] = useState<string>('#e91e63');

  const newAsset = () => {
    return {
      label: label,
      data: Liability
        ? [null, null, -Math.abs(InputValue)]
        : [null, InputValue],
      backgroundColor: backgroundColor,
    };
  };

  return (
    <Dialog
      open={ToogleAddDialog}
      onClose={handleToogleAddDialog}
      aria-labelledby='responsive-dialog-title'>
      <DialogTitle id='responsive-dialog-title'>{'Add asset'}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            id='standard-basic'
            label='Label'
            variant='standard'
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={Liability}
                  onChange={(e) => setLiability(e.target.checked)}
                />
              }
              label='Liability'
            />
          </FormGroup>
          <TextField
            id='outlined-number'
            label='Value - 1/1000'
            type='number'
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                max: 100000,
                min: -100000,
              },
            }}
            value={InputValue}
            onChange={(e) => {
              const val = parseInt(e.target.value);
              setValue(isNaN(val) ? 0 : val);
            }}
          />

          <CirclePicker
            color={backgroundColor}
            onChange={(e: any) => setbackgroundColor(e.hex)}
            colors={palette}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleToogleAddDialog}>
          Cancel
        </Button>
        <Button onClick={() => SaveNewAsset(newAsset())} autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
