import { useEffect, useState } from 'react';
import { Chart } from '../components/chart';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { FetchAssets } from '../fire/FetchAssets';

type AssetProps = {
  label: string;
  data: (number | null)[];
  backgroundColor: string;
  hidden: boolean;
};

type AssetDoc = {
  assets: AssetProps[];
  liabilities: AssetProps[];
};

export const Overview = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPrice, setCurrentPrice] = useState<number>(0);
  const [sharesOutstanding, setSharesOutstanding] = useState<number>(0);
  const [Assets, setAssets] = useState<AssetProps[]>([]);
  const [Liabilities, setLiabilities] = useState<AssetProps[]>([]);

  const search = useLocation().search;
  const ticker = new URLSearchParams(search).get('ticker') ?? '';

  const encodedParams = new URLSearchParams();
  encodedParams.append('symbol', ticker);

  const options = {
    method: 'POST',
    url: 'https://yahoo-finance97.p.rapidapi.com/stock-info',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      'X-RapidAPI-Key': '80836f9e55mshbe0d5e288c28313p1d54c8jsn03b45bae8bc5',
      'X-RapidAPI-Host': 'yahoo-finance97.p.rapidapi.com',
    },
    data: encodedParams,
  };

  useEffect(() => {
    axios
      .request(options)
      .then(function (response) {
        setCurrentPrice(response.data.data.currentPrice);
        setSharesOutstanding(response.data.data.sharesOutstanding);
        FetchAssets(ticker)
          .then((asset: any) => {
            setAssets(asset.assets ?? []);
            setLiabilities(asset.liabilities ?? []);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  const Stock = {
    ticker: ticker ?? '',
    sharesOutstanding: sharesOutstanding,
    price: currentPrice,
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Chart
          Stock={Stock}
          SuppliedAssets={Assets}
          SuppliedLiabilities={Liabilities}
        />
      )}
    </>
  );
};
