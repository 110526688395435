import { useState, useRef, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { AddDialog } from './addDialog';
import { BarChart } from './bar';
import { DeleteDialoge } from './deleteDialog';
import Grid from '@mui/material/Grid';

import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import _ from 'lodash';

type AssetProps = {
  label: string;
  data: (number | null)[];
  backgroundColor: string;
};

type StockProps = {
  ticker: string;
  sharesOutstanding: number;
  price: number;
};

type Props = {
  Stock: StockProps;
  SuppliedAssets?: AssetProps[];
  SuppliedLiabilities?: AssetProps[];
};

type ChartIndex = {
  datasetIndex: number;
  index: number;
};

const useStyles = makeStyles((theme: any) => ({
  Fab1: {
    margin: 5,
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: 60,
      top: 60,
    },
  },
  Fab2: {
    margin: 5,
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: 60,
      top: 10,
    },
  },
}));

export const Chart = ({
  Stock,
  SuppliedAssets,
  SuppliedLiabilities,
}: Props) => {
  // THEME
  const theme = useTheme();
  const classes = useStyles(theme);
  const SmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  // STOCK INFORMATION
  const ticker = Stock.ticker;
  const stockprice = Stock.price;
  const sharesOutstanding = Stock.sharesOutstanding;
  const MarketValue = Math.round((stockprice * sharesOutstanding) / 1000000);
  const AssetsAndLiabilities = [
    ...(SuppliedAssets ?? []),
    ...(SuppliedLiabilities ?? []),
  ];
  // const liabilities = SuppliedLiabilities ?? [];
  const MarketCap = {
    label: 'Market Cap',
    data: [MarketValue, null],
    backgroundColor: 'rgb(5, 9, 13,.4)',
  };

  const green = 'rgb(25, 200, 13)';
  const red = 'rgba(200,25,13)';

  // REFS
  const chartBoxRef = useRef<any>(null);

  // STATE USED FOR CHARTING
  const [ChartBoxHeight, setChartBoxHeight] = useState<number>(0);
  const [ChartBoxWidth, setChartBoxWidth] = useState<number>(0);
  const [selected, setSelected] = useState<ChartIndex>({
    datasetIndex: 1,
    index: 1,
  });
  const [ToogleAddDialog, setToogleAddDialog] = useState<boolean>(false);
  const [ToogleDeleteDialog, setToogleDeleteDialog] = useState<boolean>(false);

  const [datasets, setDatasets] = useState([
    ...[MarketCap],
    ...AssetsAndLiabilities,
  ]);
  const labels = ['Mcap', 'Assets'];

  [SuppliedLiabilities ?? []].length > 0 && labels.push('Liability');

  const Mcap = datasets
    ?.find((o) => o.label === 'Market Cap')
    ?.data.filter((item: any) => item)
    .shift() as number;

  const stockPriceWeightetForMcap = Mcap / (sharesOutstanding / 1000000);

  useEffect(() => {
    if (chartBoxRef.current) {
      let height = chartBoxRef.current.offsetHeight;
      let width = chartBoxRef.current.offsetWidth;
      setChartBoxWidth(width);
      setChartBoxHeight(height);
    }
  }, [chartBoxRef]);

  const TotalAssets = datasets.reduce((accumulator: any, assetValue: any) => {
    if (!assetValue.hidden) {
      return accumulator + (assetValue.data[1] ?? 0);
    } else {
      return accumulator;
    }
  }, 0);

  const TotalLiabilites = datasets.reduce(
    (accumulator: any, liability: any) => {
      if (!liability.hidden) {
        return accumulator + (liability.data[2] ?? 0);
      } else {
        return accumulator;
      }
    },
    0
  );

  const difference = ((TotalAssets - Mcap) / Mcap) * 100;

  // const FairValueStockPrice = stockprice * ((difference + 100) / 100);
  const FairValueStockPrice = (TotalAssets / sharesOutstanding) * 1000000;
  const FairValueStockPriceDeductedliability =
    ((TotalAssets + TotalLiabilites) / sharesOutstanding) * 1000000;

  const direction = Mcap < TotalAssets;

  const selectedValue = datasets[selected.datasetIndex]?.data
    .filter((item: any) => item)
    .shift() as number;

  const selectedColor = datasets[selected.datasetIndex ?? 0]
    ?.backgroundColor as string;

  const selectedLabel = datasets[selected.datasetIndex ?? 0]?.label as string;

  const CreateData = (value: number) => {
    var i = selected.index;
    return i == 1 ? [null, value] : [null, null, -Math.abs(value)];
  };

  const onSliderChange = (e: any) => {
    const ass = [...datasets];
    const sel = selected.datasetIndex;
    ass[sel] = {
      ...ass[sel],
      data: CreateData(e.target.value),
    };
    setDatasets(ass);
  };

  const onSliderChangeMcap = (e: any) => {
    const ass = [...datasets];
    ass[0] = {
      ...ass[0],
      data: [e.target.value, null],
    };
    setDatasets(ass);
  };

  const maxSlider = useMemo(
    () => (selectedValue < 0 ? Math.abs(selectedValue) : selectedValue * 5),
    [selected]
  );
  const maxSliderMcap = useMemo(
    () => Math.round((Mcap * 10) / 1000) * 1000,
    []
  );

  const handleToogleAddDialog = () => {
    setToogleAddDialog(!ToogleAddDialog);
  };

  const handleToogleDeleteDialog = () => {
    setToogleDeleteDialog(!ToogleDeleteDialog);
  };

  const SaveNewAsset = (newAsset: any) => {
    setDatasets([...datasets, ...[newAsset]]);

    setToogleAddDialog(!ToogleAddDialog);
  };

  const DeleteAsset = () => {
    const A = [...datasets];
    A.splice(selected.datasetIndex, 1);
    const chartDataCopy = _.cloneDeep(A);
    setDatasets(chartDataCopy);

    setSelected({
      datasetIndex: 1,
      index: 1,
    });
    setToogleDeleteDialog(!ToogleDeleteDialog);
  };

  const mCapMarks = Array.apply(null, Array(6)).map((val, idx) => {
    const li = [100, 80, 60, 40, 20, 0];
    const value = Math.round((maxSliderMcap * li[idx]) / 100 / 1000) * 1000;
    return {
      key: `mCapMarks${idx}`,
      value: value,
      label: `${value}`,
    };
  });
  const AssetsMarks = Array.apply(null, Array(6)).map((val, idx) => {
    const li = [100, 80, 60, 40, 20, 0];
    const value = Math.round((maxSlider * li[idx]) / 100);
    return {
      key: `AssetsMarks${idx}`,
      value: value,
      label: `${value}`,
    };
  });

  const onSliderClick = (e: any) => {
    if (e.detail == 2) {
      const dts = [...datasets];
      dts[0] = {
        ...dts[0],
        data: [MarketCap.data[0], null],
      };
      setDatasets(dts);
    }
  };
  const onSliderClickAssets = (e: any) => {
    if (e.detail == 2) {
      const dts = [...datasets];
      dts[selected.datasetIndex] = {
        ...dts[selected.datasetIndex],
        data: [null, AssetsAndLiabilities[selected.datasetIndex - 1].data[1]],
      };
      setDatasets(dts);
    }
  };

  const Information = () => {
    return (
      <Box sx={{ textAlign: 'left', marginTop: SmallDevice ? 10 : 0 }}>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          Asset valuation
        </Typography>
        <Typography variant='h4' component='div'>
          {ticker.toUpperCase()}
        </Typography>
        <Typography sx={{ mt: 2.5, mb: 1.5 }} variant='body1'>
          Assets / Stockprice
          <br />
          <span
            style={{
              color: !direction ? red : green,
              fontWeight: 'bold',
            }}>
            {Math.round(difference)}%
          </span>
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant='body1'>
          Current stockprice
          <br />
          <span style={{ fontWeight: 'bold' }}>
            {stockPriceWeightetForMcap.toFixed(2)}
          </span>{' '}
          kr
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant='body1'>
          Asset valuation
          <br />
          <span style={{ color: !direction ? red : green, fontWeight: 'bold' }}>
            {FairValueStockPrice.toFixed(2)}
          </span>{' '}
          kr
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant='body1'>
          Asset - liabilites
          <br />
          <span style={{ color: !direction ? red : green, fontWeight: 'bold' }}>
            {FairValueStockPriceDeductedliability.toFixed(2)}
          </span>{' '}
          kr
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant='body1'>
          Market Capitalization
          <br />
          <span style={{ fontWeight: 'bold' }}>{Mcap}</span> MNOK
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant='body1'>
          Asset Capitalization
          <br />
          <span style={{ fontWeight: 'bold' }}>{TotalAssets}</span> MNOK
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant='body1'>
          Total liabilities
          <br />
          <span style={{ fontWeight: 'bold' }}>{TotalLiabilites}</span> MNOK
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {ToogleDeleteDialog && (
        <DeleteDialoge
          ToogleDeleteDialog={ToogleDeleteDialog}
          handleToogleDeleteDialog={handleToogleDeleteDialog}
          DeleteAsset={DeleteAsset}
          label={selectedLabel}
        />
      )}
      {ToogleAddDialog && (
        <AddDialog
          ToogleAddDialog={ToogleAddDialog}
          handleToogleAddDialog={() => handleToogleAddDialog()}
          SaveNewAsset={(newAsset) => SaveNewAsset(newAsset)}
        />
      )}

      <Grid container spacing={2} justifyContent='center'>
        <Grid item lg={8} md={10} xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item md={2} xs={12}>
                  <Information />
                </Grid>
                <Grid item md={10} xs={12}>
                  <Stack
                    sx={{ position: 'relative' }}
                    spacing={1}
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    ref={chartBoxRef}>
                    <Box width={'100%'} style={{ position: 'relative' }}>
                      <BarChart
                        setSelected={(selected) => setSelected(selected)}
                        labels={labels}
                        datasets={datasets}
                        setDatasets={setDatasets}
                      />
                      <Fab
                        className={classes.Fab2}
                        color='primary'
                        size='small'
                        aria-label='add'
                        onClick={() => setToogleAddDialog(true)}>
                        <AddIcon />
                      </Fab>
                      <Fab
                        className={classes.Fab1}
                        disabled={selected.datasetIndex == 0 ? true : false}
                        size='small'
                        style={{
                          backgroundColor: selectedColor
                            ? selectedColor
                            : 'rgba(0,100,255,.4)',
                        }}
                        aria-label='delete'
                        onClick={() => setToogleDeleteDialog(true)}>
                        <DeleteIcon />
                      </Fab>
                    </Box>
                    <Stack
                      direction='column'
                      justifyContent='center'
                      alignItems='center'
                      spacing={2}>
                      <Slider
                        size='small'
                        onClick={onSliderClick}
                        value={Mcap}
                        aria-label='Default'
                        style={{
                          height: 10,
                          margin: 'auto',
                          width: ChartBoxWidth,
                          color: MarketCap.backgroundColor,
                        }}
                        valueLabelDisplay='auto'
                        onChange={(res: any) => onSliderChangeMcap(res)}
                        step={10}
                        marks={mCapMarks}
                        max={maxSliderMcap}
                      />
                      <Slider
                        size='small'
                        value={
                          selectedValue < 0
                            ? Math.abs(selectedValue)
                            : selectedValue
                        }
                        aria-label='Default'
                        style={{
                          height: 10,
                          margin: 'auto',
                          width: ChartBoxWidth,
                          color: selectedColor
                            ? selectedColor
                            : 'rgba(0,100,255,.4)',
                        }}
                        valueLabelDisplay='auto'
                        onChange={(res: any) => onSliderChange(res)}
                        onClick={onSliderClickAssets}
                        step={10}
                        marks={AssetsMarks}
                        max={maxSlider}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
